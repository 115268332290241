<template>
  <div>
    <sticky-top>
      <div class="title">
        <span>{{ isCreate ? '新增广告' : '编辑广告' }}</span>
        <span class="back" @click="back"> <i class="iconfont icon-fanhui"></i> 返回 </span>
        <el-divider></el-divider>
      </div>
    </sticky-top>
    <div class="container">
      <div class="wrap">
        <el-row>
          <el-col :xl="10" :lg="16" :md="20" :sm="24" :xs="24">
            <el-form :model="form" status-icon ref="form" label-width="100px" :rules="formRules">
              <el-form-item label="名称" prop="name">
                <el-input v-model="form.name" placeholder="请填写名称" clearable></el-input>
              </el-form-item>
              <el-form-item label="类型" prop="type">
                <el-select v-model="form.type" placeholder="请选择类型">
                  <el-option label="无向导" :value="0"></el-option>
                  <el-option label="活动向导" :value="1"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="活动id" prop="activity_id" v-show="form.type == 1">
                <el-input v-model="form.activity_id" placeholder="请填写活动id" clearable></el-input>
              </el-form-item>
              <el-form-item label="图片" prop="img_id">
                <upload-imgs :max-num="maxNum" ref="uploadEle" :value="initData" />
              </el-form-item>
              <el-form-item label="状态">
                <el-switch
                  v-model="form.is_show"
                  active-text="展示"
                  inactive-text="隐藏"
                  :active-value="1"
                  :inactive-value="0"
                ></el-switch>
              </el-form-item>
              <el-form-item class="submit">
                <el-button type="primary" @click="submitForm('form')">保 存</el-button>
                <el-button @click="resetForm('form')">重 置</el-button>
              </el-form-item>
            </el-form>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>

<script>
import Banner from '@/model/banner'
import UploadImgs from '@/component/base/upload-image'
import StickyTop from '@/component/base/sticky-top/sticky-top'

export default {
  components: {
    UploadImgs,
    StickyTop,
  },
  props: {
    editID: {
      type: Number,
    },
    editItem: {
      type: Object,
      default: () => {},
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
    bannerId: {
      type: Number,
      default: null,
    },
  },
  data() {
    const checkInfo = (rule, value, callback) => {
      if (!value || value.length == 0) {
        console.log(rule)
        ElMessage.warning('请将信息补充完整')
        callback(new Error('请将信息补充完整'))
      }
    }
    const checkImg = async (rule, value, callback) => {
      // 获取图片数据
      let val = await this.$refs.uploadEle.getValue()
      console.log('checkImg', val, rule)
      if (!val || val.length == 0) {
        ElMessage.warning('请将信息补充完整')
        callback(new Error('请将信息补充完整'))
      }
      this.form.img_id = val[0].imgId
      callback()
    }
    return {
      maxNum: 1,
      form: {
        img_id: '',
        activity_id: '',
        type: null,
        name: '',
        is_show: 1,
      },
      rules: {
        minWidth: 10,
        minHeight: 10,
        maxSize: 5,
      },
      initData: [],
      formRules: {
        type: [{ message: '请将信息补充完整', trigger: 'blur', required: true }],
        activity_id: [{ message: '请将信息补充完整', trigger: 'blur', required: false }],
        img_id: [{ validator: checkImg, trigger: 'blur', required: true, message: '请将信息补充完整' }],
      },
    }
  },
  async mounted() {
    if (!this.isCreate) {
      // const res = await Banner.getBannerItem(this.editID)
      this.initData = [
        {
          src: this.editItem?.url,
          imgId: this.editItem?.img_id,
          id: this.editItem.img_id,
          display: this.editItem.img.url,
        },
      ]
      this.form = this.editItem
    }
  },
  methods: {
    async getValue() {
      const val = await this.$refs.uploadEle.getValue()
      if (val && val.length > 0) {
        let imgArr = val.map(item => item.id)
        this.form.img_id = imgArr.join(',')
      }
    },
    async submitForm() {
      await this.getValue()
      // if (typeof(this.form.type) !== 'number' || !this.form.img_id || (this.form.type == 1 && !this.form.activity_id)) return this.$message.warning('请将信息补充完整')
      this.$refs.form.validate(async valid => {
        if (valid) {
          const form = { ...this.form }
          let res
          if (form.type == 0) delete form.activity_id
          if (this.isCreate) {
            form.banner_id = this.bannerId
            res = await Banner.addBannerItem(form)
          } else {
            res = await Banner.editBannerItem(this.editID, form)
          }
          if (res.code < window.MAX_SUCCESS_CODE) {
            this.$message.success(`${res.message}`)
            this.resetForm('form')
            this.$emit('editClose')
          }
        }
      })
    },
    // 重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields()
    },
    back() {
      this.$emit('editClose')
    },
  },
  watch: {
    'form.type': function formType(newVal) {
      console.log('form.type', newVal)
      if (newVal == 1) this.formRules.activity_id[0].required = true
      else {
        this.formRules.activity_id[0].required = false
        this.form.activity_id = ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.el-divider--horizontal {
  margin: 0;
}

.container {
  .title {
    height: 59px;
    line-height: 59px;
    color: $parent-title-color;
    font-size: 16px;
    font-weight: 500;
    text-indent: 40px;

    .back {
      float: right;
      margin-right: 40px;
      cursor: pointer;
    }
  }

  .wrap {
    padding: 20px;
  }

  .submit {
    float: left;
  }
}
</style>
